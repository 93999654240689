<template>
    <div>
        <!-- if the selected_rep_id in the store is set to -1 i.e. no specific user has been selected then the following will show -->
        <simpleLayout
            v-if="!hasRepSelected"
            :items_count="this.all_reps.length"
        >
            <template #buttons>
                <button
                    @click="addRep"
                    type="button"
                    class="btn btn-success mb-2 mr-2"
                >
                    <i class="mdi mdi-plus mr-1"></i> Add a new Rep
                </button>
            </template>
            <template #table>
                <repsTable />
            </template>
        </simpleLayout>
        <!-- if selected_rep_id from the store is not equal to -1 then the following will show i.e. a specific user id has been set -->
        <div v-if="hasRepSelected">
            <repsDetails />
        </div>
        <repsAddModal v-if="show_modal_addRep" />
    </div>
</template>

<script>
import simpleLayout from "@/components/layout-templates/simple-layout.vue";
import repsTable from "./repsTable";
import repsDetails from "./repsDetails";
import repsAddModal from "./repsAddModal.vue";

import { repMethods, repComputed } from "@/state/helpers";

export default {
    components: { simpleLayout, repsTable, repsDetails, repsAddModal },
    data: () => ({
        show_modal_addRep: false,
        showModal: false,
    }),

    computed: {
        ...repComputed,
        // TODO: Delete
        hasRepSelected() {
            return this.selected_rep_id != -1;
        },
    },
    methods: {
        ...repMethods,
        addRep() {
            this.show_modal_addRep = true;
            this.$nextTick(() => {
                this.$bvModal.show("repsAddModal");
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (
                        event.type == "hidden" &&
                        event.componentId == "repsAddModal"
                    ) {
                        this.show_modal_addRep = false;
                    }
                });
            });
        },
    },
    mounted() {
        this.setSelectedRepId(-1);
    },
};
</script>

<style></style>
