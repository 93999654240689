<template>
    <b-modal title="Create a rep" id="repsAddModal" hide-footer>
        <b-overlay :show="busySaving">
            <!-- form -->
            <b-form @submit.stop.prevent="onSubmit">
                <b-form-group
                    id="example-input-group-1"
                    label="Name"
                    label-for="example-input-1"
                >
                    <b-form-input
                        id="example-input-1"
                        name="example-input-1"
                        v-model="$v.form.name.$model"
                        :state="validateState('name')"
                        aria-describedby="input-1-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback id="input-1-live-feedback"
                        >This is a required field and must be at least 3
                        characters.</b-form-invalid-feedback
                    >
                </b-form-group>

                <b-form-group
                    id="example-input-group-1"
                    label="Email"
                    label-for="example-input-1"
                >
                    <b-form-input
                        id="example-input-1"
                        name="example-input-1"
                        v-model="$v.form.email.$model"
                        :state="validateState('email')"
                        aria-describedby="input-1-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback id="input-1-live-feedback"
                        >Please enter a valid email</b-form-invalid-feedback
                    >
                </b-form-group>
                <div class="modal-footer">
                    <b-button type="submit" variant="primary"
                        >Create rep</b-button
                    >
                    <button
                        type="button"
                        class="btn btn-secondary"
                        @click="closeModal()"
                    >
                        Close
                    </button>
                </div>
            </b-form>
        </b-overlay>
    </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
//eslint-disable-next-line
import { required, email, minLength } from "vuelidate/lib/validators";
import { repMethods, repComputed } from "@/state/helpers";
//eslint-disable-next-line
import Swal from "sweetalert2";

export default {
    mixins: [validationMixin],
    data() {
        return {
            formSubmit: false,
            busySaving: false,
            form: {
                branch_id: -1,
                role_id: 3,
                name: null,
                email: null,
            },
        };
    },
    validations: {
        form: {
            email: {
                required,
                email,
            },
            name: {
                required,
                minLength: minLength(3),
            },
        },
    },
    computed: { ...repComputed },
    methods: {
        ...repMethods,
        validateState(name) {
            if (this.formSubmit) {
                const { $dirty, $error } = this.$v.form[name];
                return $dirty ? !$error : null;
            }
        },
        onSubmit() {
            this.formSubmit = true;

            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            this.busySaving = true;

            this.createRepUser(this.form).then(() => {
                this.busySaving = false;
                this.$bvModal.hide("repsAddModal");
                Swal.fire(
                    `New rep added`,
                    `${this.form.name} was added as a rep`,
                    "success"
                );

                this.setRepUsers();
            });
        },
        closeModal() {
            this.$bvModal.hide("repsAddModal");
        },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped></style>
