<template>
    <div>
        <webix-ui :config="ui" v-model="all_reps"></webix-ui>
        <div id="usersPaging"></div>

        <editRepModal v-if="showModal_editRepModal" />
    </div>
</template>

<script>
import Swal from "sweetalert2";
import { adminMethods, repMethods, repComputed } from "@/state/helpers";
import editRepModal from "./modals/editRepModal.vue";

export default {
    components: { editRepModal },
    data: () => ({
        showModal_editRepModal: false,
        ui: {
            id: "repsTable",
            view: "datatable",
            css: "datatableUsers webix_header_border webix_data_border",
            autoheight: true,
            //hover: "datatableHover",
            minHeight: 60,
            minColumnWidth: 120,
            rowHeight: 40,
            scrollY: false,
            scrollX: true,
            resizeColumn: true,
            select: false,
            pager: {
                template: "{common.prev()} {common.pages()} {common.next()}",
                container: "usersPaging",
                size: 15,
                group: 5,
            },
            columns: [
                {
                    id: "actions",
                    header: [
                        {
                            text: "Actions",
                            rowspan: "2",
                            css: { "text-align": "center" },
                        },
                    ],
                    fillspace: 0.1,
                    template: function () {
                        //eslint-disable-next-line
                        let id = 5;
                        return `
                        <div class="tableIcons">
                            <i class="mdi mdi-trash-can deleteAction"></i>
                            <i class="mdi mdi-account-edit editAction"></i>
                            <i class="mdi mdi-eye viewAction"></i>
                            &nbsp
                            </div>`;
                    },
                },
                {
                    //these need to match the returned json object propertie names
                    id: "name",
                    header: ["Name", { content: "textFilter" }],
                    tooltip: false,
                    fillspace: 0.3,
                },
                {
                    id: "email",
                    header: ["Email", { content: "textFilter" }],
                    fillspace: 0.5,
                },
            ],
            onClick: {
                ...repMethods,
                //eslint-disable-next-line
                // viewAction: function (id, e, trg) {
                //     //NB: this.getItem(e.row) will return the id if it is in the object in the store
                //     let el = this.$scope.$parent;
                //     el.setSelectedRepId(e.row);
                // },
                //eslint-disable-next-line
                viewAction: function (id, e, trg) {
                    let el = this.$scope.$parent;
                    //get the id to send to the url
                    let idForUrl = e.row;
                    el.$router.push({
                        // name: `somename`,
                        path: `/reps/view/${idForUrl}`,
                        params: { idForUrl },
                    });
                },
                //eslint-disable-next-line
                deleteAction: function (id, e, trg) {
                    const el = this.$scope.$parent;
                    const item = this.getItem(e.row);
                    Swal.fire({
                        title: `Are you sure you want to delete
                            "${item.name}" ?`,
                        text: "You won't be able to revert this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#34c38f",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonText: "Yes, delete!",
                    }).then((result) => {
                        if (result.value) {
                            el.ready = false;
                            el.deleteRepUser({ id: e.row }).then(() => {
                                // el.getStockists().then(() => {
                                //     el.ready = true;
                                // });
                                Swal.fire("Rep deleted", "", "success");
                                //refresh the table on the screen
                                el.setRepUsers();
                            });
                        }
                    });
                },
                //eslint-disable-next-line
                editAction: function (id, e, trf) {
                    //eslint-disable-next-line
                    const el = this.$scope.$parent;
                    //get the table rows details
                    el.setSelectedUserToEdit(this.getItem(e.row));
                    el.editUser();
                },
            },
        },
    }),
    computed: {
        ...repComputed,
    },
    methods: {
        ...adminMethods,
        ...repMethods,
        editUser() {
            this.showModal_editRepModal = true;
            this.$nextTick(() => {
                this.$bvModal.show("editRepModal");
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "editRepModal") {
                        this.showModal_editRepModal = false;
                    }
                });
            });
        },
    },
    mounted() {
        if (this.all_reps.length == 0) {
            this.setRepUsers();
        }
    },
};
</script>

<style lang="scss"></style>
